import axios from "axios";
import { webUrl } from "@/common/config";
import { showToast, toPage } from "@/common/utils";
import store from "@/store";
let config = {
    baseURL: webUrl,
    timeout: 10000,
};
const _axios = axios.create(config);
// _axios.defaults.withCredentials = true;
_axios.interceptors.response.use((res) => {
    if (res.data) {
        if (res.data.success == false) {
            if (res.data.errorCode && res.data.errorCode !== 200) {
                if (res.data.message)
                    showToast(res.data.message);
                const errorCode = res.data.errorCode;
                switch (errorCode) {
                    case 10000:
                        return store.commit("login");
                    case 10001:
                        return toPage("/register");
                    case 10031:
                        return toPage("/register");
                    case 10103:
                        return toPage("/");
                    default:
                        if (errorCode == 10002) {
                            return toPage("/school");
                        }
                }
            }
            return res;
        }
        else {
            return res;
        }
    }
    else {
        showToast("网络不稳定", "fail");
    }
}, (err) => {
    if (err) {
        return showToast("网络不稳定", "fail");
    }
    return Promise.reject(err);
});
export function http_get(url = "", params = {}, options = {}) {
    let headers = {
        "Content-Type": "application/json;charset=UTF-8",
    };
    if (store.state.token)
        headers["Authorization"] = "Bearer " + store.state.token;
    return new Promise((resolve, reject) => {
        _axios({
            url,
            params,
            headers: headers,
            method: "GET",
        })
            .then((res) => {
            if (res) {
                resolve(res.data);
            }
        })
            .catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}
export function http_post(url = "", params = {}, options = {}) {
    return new Promise((resolve, reject) => {
        let headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        if (store.state.token)
            headers["Authorization"] = "Bearer " + store.state.token;
        _axios({
            url,
            data: params,
            headers: headers,
            method: "POST",
        })
            .then((res) => {
            resolve(res.data);
        })
            .catch((error) => {
            reject(error);
        });
    });
}
