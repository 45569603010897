import { http_get, http_post } from "./index";
//登录操作 静默授权
export const loginApi = () => http_get("/jump", {
    wechat_scope: "snsapi_base",
    target: window.location.href,
});
//非静默授权获取头像
export const getAvatarUrlApi = () => http_get("/jump", {
    wechat_scope: "snsapi_userinfo",
    target: window.location.href,
});
//注册页获取头像和昵称
export const getAvatarApi = () => http_get("/api/avatar");
//获取微信js sdk配置
export const getJsSdkConfig = (url) => http_get("/js_sdk", { url: url });
//发送短信验证码
export const sendCodeApi = (mobile) => http_post("/api/sms", { mobile });
//获取用户信息
export const getUserApi = () => http_get("/api/user");
export const createUserApi = (data) => http_post("/api/createUser", data);
export const saveUserApi = (data) => http_post("/api/user", data);
//获取用户信息
export const getIndex = () => http_get("/api/index", { refer: window.location.href });
//获取分类列表
export const getCateListApi = () => http_get("/api/categories", {});
//获取文章列表
export const getPostListApi = (data) => http_get("/api/threads", data);
//获取某条信息的详情
export const getPostApi = (id) => http_get("/api/threads/" + id, {});
//点赞文章或评论
export const like = (data) => http_post("/api/like", data);
//评论
export const comment = (data) => http_post("/api/discuss", data);
//获取文章评论列表
export const getPostCommentList = (tid) => http_get("/api/discuss", { tid: tid });
//获取某条评论详情
export const getDiscussApi = (did) => http_get("/api/discuss/" + did);
export const savePost = (data) => http_post("/api/threads", data);
export const changePostStatus = (data) => http_post("/api/changeThread", data);
export const verify = (data) => http_post("/api/credential", data);
//获取用户实名状态
export const getVerify = () => http_get("/api/credential");
export const lockApi = (data) => http_post("/api/lock", data);
//获取消息列表
export const getMsgListApi = (data) => http_get("/api/notices", data);
//清空消息
export const clearMsgNumApi = (msg_id) => http_post("/api/notices", { id: msg_id });
//通过文章id获取code
export const getCodeApi = (tid) => http_post("/api/code", { tid });
//获取学校配置信息
export const getSchoolApi = () => http_get("/api/school");
//获取学校列表
export const getSchoolListApi = (s) => http_get("/api/schools", { s: s });
//权限判断
export const checkApi = () => http_get("/api/check");
//删除评论
export const delDiscussApi = (did) => http_post("/api/discuss/" + did, { is_del: 1 });
//拉黑评论
export const authUpdateDis = (did, action) => http_post("/api/auth/discuss/" + did, { action: action });
//获取广告图片列表
export const getAdApi = () => http_get("/api/ad");
//获取页面详情
export const getPageDetailApi = (code) => http_get("/api/page/" + code);
//用户签到获取积分
export const signScoreApi = () => http_post("/api/sign");
export const getScodeLogListApi = (data) => http_get("/api/score", data);
//投票操作
export const voteApi = (tid, option) => http_post("/api/vote", { tid, option });
//支出日志
export const getTradeLogApi = (data) => http_get("/api/score/trades", data);
