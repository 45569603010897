import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "default-passive-events";
//导入自适应
import "amfe-flexible";
//引入vant样式
import "vant/lib/index.css";
//全局注册组件
import globalComponents from "./components/global";
import { Lazyload } from "vant";
const app = createApp(App);
app.use(Lazyload).use(globalComponents).use(store).use(router).mount("#app");
