import { getStorage, setStorage } from "@/common/utils/localStorage";
import { emptyArr } from "@/common/utils/type";
import { getAdApi, getCateListApi, getPostListApi, getSchoolApi, getUserApi, } from "@/http/api";
const getters = {
    //获取分类
    cateList: async (state) => {
        if (state.cateList.length == 0) {
            const cateList = window.localStorage.getItem("cateList");
            if (cateList) {
                state.cateList = JSON.parse(cateList);
            }
            else {
                //后台请求分类
                const { data } = await getCateListApi();
                state.cateList = data;
                window.localStorage.setItem("cateList", JSON.stringify(data));
            }
        }
        return state.cateList;
    },
    //获取学校信息
    school: async (state) => {
        if (state.school.school_id == 0) {
            const school = window.localStorage.getItem("school");
            if (school) {
                state.school = JSON.parse(school);
            }
            else {
                //请求学校
                const { data } = await getSchoolApi();
                state.school = data;
                window.localStorage.setItem("school", JSON.stringify(data));
            }
        }
        return state.school;
    },
    //获取用户信息
    userinfo: async (state) => {
        if (state.userinfo.id == 0) {
            const { data } = await getUserApi();
            if (!emptyArr(data))
                state.userinfo = data;
        }
        return state.userinfo;
    },
    //获取用户权限
    auth: (state, getters) => {
        return state.userinfo.auth || 0;
    },
    //获取用户未读消息
    msgNum: (state) => {
        return state.userinfo.msg_num || 0;
    },
    userId: (state) => {
        return state.userinfo.id || 0;
    },
    adList: async (state) => {
        if (state.adList.length == 0) {
            const adList = getStorage("adList");
            if (adList) {
                state.adList = adList;
            }
            else {
                //获取广告图片
                const { data } = await getAdApi();
                state.adList = data;
                setStorage("adList", data, 1000 * 60 * 5);
            }
        }
        return state.adList;
    },
    score: (state) => {
        return state.userinfo.score || 0;
    },
    hotList: async (state) => {
        if (state.hotList.length == 0) {
            const hotList = getStorage("hotList");
            if (hotList) {
                state.hotList = hotList;
            }
            else {
                //获取广告图片
                const { data } = await getPostListApi({
                    is_hot: 1,
                });
                state.hotList = data;
                setStorage("hotList", data, 1000 * 60 * 5);
            }
        }
        return state.hotList;
    },
};
export default getters;
