const state = {
    //学校数据存储
    school: {
        school_id: 0,
        code: 0,
        name: "",
        shortname: "",
        icon_img: "",
        students: 0,
    },
    //登录标识
    token: "",
    //登录状态
    loginStatus: false,
    //用户数据存储
    userinfo: {
        id: 0,
        nickname: "",
        avatar: "",
        status: 1,
        auth: 0,
        msg_num: 0,
        is_verify: 0,
        score: 0,
        can_sign: 0,
    },
    //分类列表
    cateList: [],
    //广告
    adList: [],
    //热榜列表
    hotList: [],
};
export default state;
