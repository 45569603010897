import { http_get, http_post } from "./index";
//管理端对文章的操作
export const updatePostApi = (tid, action) => http_post("/api/auth/thread/" + tid, { action: action });
//获取举报文章列表
export const getLockPostList = (data) => http_get("/api/auth/thread", { ...data, is_lock: 1 });
//获取举报帖子关联的举报详情
export const getLockPostViewList = (tid) => http_get("/api/auth/thread/" + tid);
//获取举报评论列表
export const getLockDiscussList = (data) => http_get("/api/auth/discuss", { ...data, is_lock: 1 });
//获取拉黑用户列表
export const getLockUserList = (data) => http_get("/api/auth/user", data);
//解封用户
export const updateUserApi = (user_id, status) => http_post("/api/auth/user/" + user_id, { status });
//获取实名认证待审核列表
export const getverfyList = (data) => http_get("/api/auth/verify", { ...data, is_lock: 1 });
//审核
export const updateVerifyApi = (id, action, cause = "") => http_post("/api/auth/verify/" + id, { action: action, cause: cause });
//批量操作文章
export const updateAllPostApi = (action) => http_post("/api/auth/thread", { action: action });
//批量操作实名
export const updateAllVerifyApi = (action) => http_post("/api/auth/verify", { action: action });
//查询学校概况
export const getSchoolAnalyzeApi = () => http_get("/api/auth/school");
