const router = [
    {
        path: "/home",
        name: "home",
        component: () => import("../views/Home.vue"),
        redirect: "/",
        meta: {
            keepAlive: true,
        },
        children: [
            {
                name: "index",
                path: "/",
                component: () => import("../views/index/index.vue"),
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: "/my",
                component: () => import("../views/index/my.vue"),
            },
            {
                path: "/search",
                component: () => import("../views/index/search.vue"),
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: "/msg",
                component: () => import("../views/index/msg.vue"),
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: "/points/mall",
                name: "pointMall",
                component: () => import("../views/points/mall.vue"),
                meta: {
                    keepAlive: true,
                },
            },
        ],
    },
];
export default router;
