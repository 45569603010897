const routers = [
    {
        path: "/points/detail",
        name: "pointDetail",
        component: () => import("../views/points/detail.vue"),
    },
    {
        path: "/points/order",
        name: "pointOrder",
        component: () => import("../views/points/order.vue"),
    },
    {
        path: "/points/order/detail",
        name: "pointOrderDetail",
        component: () => import("../views/points/orderDetail.vue"),
    },
    {
        path: "/points/good",
        name: "pointGood",
        component: () => import("../views/points/goodDetail.vue"),
    },
];
export default routers;
