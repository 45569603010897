export function emptyObj(obj) {
    if (JSON.stringify(obj) == "{}") {
        return true;
    }
    return false;
}
export function emptyArr(arr) {
    if (arr.length == 0) {
        return true;
    }
    return false;
}
