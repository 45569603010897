import { loginApi } from "@/http/api";
const mutations = {
    //静默登录操作
    login: (state) => {
        //避免同时发起多次登录
        if (!window.localStorage.getItem("is_login")) {
            window.localStorage.setItem("is_login", "logining");
            loginApi().then((e) => {
                if (e.data) {
                    //存储token到缓存
                    window.localStorage.setItem("token", e.data.token);
                    state.token = e.data.token;
                    window.location.href = e.data.jump;
                }
            });
        }
    },
};
export default mutations;
