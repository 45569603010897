//公共方法
import { showToast } from "@/common/utils";
import { updateUserApi } from "@/http/adminApi";
import store from "@/store";
import { Dialog } from "vant";
//拉黑用户
export const delUser = (user_id) => {
    if (store.getters.getAuth > 0) {
        Dialog.confirm({
            title: "温馨提示",
            message: "是否要拉黑该用户？",
        }).then(() => {
            updateUserApi(user_id, 0).then((e) => {
                if (e.success) {
                    showToast("拉黑成功");
                }
            });
        });
    }
};
