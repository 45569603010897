import { cache } from "@/common/utils";
import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import points from "./points";
import merchants from "./merchant";
import home from "./home";
import forum from "./forum";
const routes = [
    ...home,
    ...forum,
    ...points,
    ...merchants,
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { top: 0 };
        }
    },
});
//路由拦截器 跳转前操作
router.beforeEach(async (to, from, next) => {
    // console.log("from", from);
    // console.log("to", to);
    //对于传参s的操作
    if (to.query.s) {
        next();
    }
    else {
        //不需要强制性加s后缀
        if (to.meta.suffix == false) {
            if (to.name == "school") {
                //清空所有用户缓存
                cache("is_login", null);
                cache("token", null);
            }
            next();
        }
        else {
            //需要加s后缀
            const school = await store.getters.school;
            to.query.s = school.code;
            next({
                path: to.path,
                query: to.query,
            });
        }
    }
});
//路由拦截器 跳转后操作
router.afterEach(async (to, from, next) => {
    const title = String(to.meta.title);
    if (title == "undefined") {
        const school = await store.getters.school;
        document.title = school.shortname + "校园集市" || "校园集市";
    }
    else {
        document.title = title;
    }
    window.scrollTo(0, 0);
});
export default router;
