import { Icon, Image, Tag, Dialog, Cell, Skeleton, Field, Swipe, SwipeItem, Divider, Tab, Tabbar, TabbarItem, List, CellGroup, Tabs, Empty, Search, Button, Form, Uploader, Switch, Picker, Popup, Row, Col, Badge, CountDown, ActionSheet, } from "vant";
import PostList from "@/components/post/post-list.vue";
import RightNav from "@/components/right-nav.vue";
import copyright from "@/components/copyright.vue";
import "vant/es/dialog/style";
import "vant/es/toast/style";
export default {
    install: (app) => {
        app.component(Icon.name, Icon);
        app.component(Image.name, Image);
        app.component(Tag.name, Tag);
        app.component(Dialog.Component.name, Dialog.Component);
        app.component(Cell.name, Cell);
        app.component(Skeleton.name, Skeleton);
        app.component(Field.name, Field);
        app.component(Swipe.name, Swipe);
        app.component(SwipeItem.name, SwipeItem);
        app.component(Divider.name, Divider);
        app.component(PostList.name, PostList);
        app.component(RightNav.name, RightNav);
        app.component(Tabs.name, Tabs);
        app.component(Tab.name, Tab);
        app.component(Tabbar.name, Tabbar);
        app.component(TabbarItem.name, TabbarItem);
        app.component(List.name, List);
        app.component(CellGroup.name, CellGroup);
        app.component(Empty.name, Empty);
        app.component(copyright.name, copyright);
        app.component(Search.name, Search);
        app.component(Button.name, Button);
        app.component(Form.name, Form);
        app.component(Uploader.name, Uploader);
        app.component(Switch.name, Switch);
        app.component(Picker.name, Picker);
        app.component(Popup.name, Popup);
        app.component(Row.name, Row);
        app.component(Col.name, Col);
        app.component(Badge.name, Badge);
        app.component(CountDown.name, CountDown);
        app.component(ActionSheet.name, ActionSheet);
    },
};
